import { Divider, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import PageLayout from "pages/layout/page-layout";
import ProductDescription from "pages/user/product-pages/krema-protiv-bora/product-description";
import Reviews from "pages/user/reviews";
import { useDocumentTitle } from "hooks/use-document-title";
import { SERVER_URL_PREFIX } from "api";
import ProductDetails from "pages/user/components/product-details";
import { Tabs, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { get } from "api";
import { useSoftUIController, setOpenConfigurator } from "context";
import ScrollableProductList from "pages/user/product-pages/components/scrollable-product-list";
import SoftTypography from "components/SoftTypography";
import { addCartItem, updateProduct } from "context";
import { RESOURCES_SERVER_URL_PREFIX } from "api";
import config from "config/config";
import { useMetaTag } from "hooks/use-document-title";
import useGTMEvent from "hooks/use-gtm-event";
import ProductDetailsSkeleton from "pages/user/components/product-details/product-details-skeleton";

const Serum = () => {
  useDocumentTitle("Serum za lice | Mama za pet – Prirodni Eliksir za Kožu");
  useMetaTag(
    "description",
    "Prirodni serum sa eteričnim uljima smilja, ruže i čajevca regeneriše kožu, uklanja nepravilnosti i hidrira lice."
  );
  useMetaTag(
    "keywords",
    "serum za lice, prirodni serum, eterična ulja, regeneracija kože, hidratacija kože, mama za pet, mama za pet prirodni proizvodi, mama za pet kozmetika, mama za pet proizvodi za lice"
  );

  const [tabIndex, setTabIndex] = useState(0);
  const sendEventToGTM = useGTMEvent();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const [controller, dispatch] = useSoftUIController();
  const { selectedCurrency, cartItems } = controller;
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, true);

  useEffect(() => {
    setIsLoadingProducts(true);
    get(`${SERVER_URL_PREFIX}/api/web/products`)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  }, []);

  const getProductsAddToCardData = (productName, productQuantity) => {
    const product = products.find((p) => p.name === productName);

    if (product) {
      const { id, name, imageLocation, quantity, unitPriceBam, unitPriceEur } = product;
      const price = selectedCurrency?.value === "bam" ? unitPriceBam : unitPriceEur;

      return {
        id: String(id),
        name: name,
        img: `${RESOURCES_SERVER_URL_PREFIX}${imageLocation}`,
        quantity: productQuantity,
        unitPrice: price,
        unitQuantity: quantity,
      };
    }

    return undefined;
  };

  const handleAddToCart = (product, eventSource) => {
    const currentProduct = cartItems.find((item) => item.id === product.id);
    get(
      `${SERVER_URL_PREFIX}/api/web/product/price/calculation?productId=${product.id}&quantity=${
        currentProduct ? currentProduct.quantity + 1 : product.quantity
      }&currency=${selectedCurrency.value.toUpperCase()}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (!currentProduct) {
          addCartItem(dispatch, {
            ...product,
            realPrice: data.realPrice,
            calculatedPrice: data.calculatedPrice,
          });
          sendEventToGTM("add_to_cart", {
            product_id: product.id,
            product_name: product.name,
            product_category: product.type,
            product_price: data.realPrice,
            product_calculated_price: data.calculatedPrice,
            currency: selectedCurrency.value,
            quantity: 1,
            page_source: "Home",
            event_source: eventSource,
          });
        } else {
          const newQuantity = currentProduct.quantity + product.quantity;
          updateProduct(dispatch, {
            ...product,
            realPrice: data.realPrice,
            calculatedPrice: data.calculatedPrice,
            quantity: newQuantity,
          });
          sendEventToGTM("update_cart_quantity", {
            product_id: product.id,
            product_name: product.name,
            product_category: product.type,
            product_price: data.realPrice,
            product_calculated_price: data.calculatedPrice,
            currency: selectedCurrency.value,
            new_quantity: newQuantity,
            page_source: "Home",
            event_source: eventSource,
          });
        }
      });
  };

  const handleAdd = (product, eventSource) => {
    setTimeout(handleConfiguratorOpen, 300);
    handleAddToCart(getProductsAddToCardData(product.name, product.quantity), eventSource);
  };

  const getSetsContainingProduct = (targetProduct, products) => {
    return products.filter((product) =>
      product.compositeProductItems?.some((item) => item.itemProduct?.id === targetProduct.id)
    );
  };

  const getCartItemQuantity = (productName) => {
    const product = cartItems.find((item) => item.name === productName);

    if (product) {
      return product.quantity;
    }

    return 0;
  };

  const getMaxCartQuantity = (productName) => {
    return config.appSettings.maxQuantityPerItem - getCartItemQuantity(productName);
  };

  return (
    <PageLayout sx={{ overflow: "hidden" }}>
      <Divider sx={{ mb: "0" }} />
      {products && products.length > 0 && !isLoadingProducts ? (
        <ProductDetails
          product={products.find((p) => p.id === 3)}
          onAddToCart={(product) => handleAdd(product, "add_to_cart_button")}
          productImages={[
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/products/common/serum-square.webp`,
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/shooting_2025/serum-1.webp`,
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/shooting_2025/serum-2.webp`,
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/shooting_2025/serum-3.webp`,
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/shooting_2025/serum-4.webp`,
          ]}
          descriptionContentArray={[
            "Receptura stara više od dvije decenije koja je prilagođena svim tipovima kože, uključujući i osjetljivu kožu sklonu alergijama i ekcemima.",
            "Smanjuje postojeće bore i sprječava nastanak novih zahvaljujući bogatstvu vitamina i antioksidanasa koji se nalaze u hladno cijeđenim i eteričnim uljima poput avokada, smilja, pšeničnih klica, kardamona i bosiljka.",
            "Intezivno hidrira i  tonira lice u roku od nekoliko sekundi.  Brzo se upija i ne ostavlja mastan trag.",
            "Sadrži hladno cijeđeno ulje pšeničnih klica koje je posebno bogato vitaminom E, dok provitamin A doprinosi otpornosti kože na štetne UV zrake.",
            "Uz delikatne arome bosiljka, kardamoma i timijana, ovaj serum ne samo da njeguje tvoju kožu, već pruža trenutke opuštanja i harmonije prije spavanja.",
          ]}
          sets={getSetsContainingProduct(
            products.find((p) => p.name === "Serum za lice"),
            products
          )}
          maxQuantity={getMaxCartQuantity("Serum za lice")}
        />
      ) : (
        <ProductDetailsSkeleton />
      )}
      <Grid
        container
        width={{ xs: "100%", lg: "100%", xl: "100%" }}
        mt={{ xs: "3rem", lg: "0" }}
        sx={{
          display: "flex",
          alignItems: "flex-start",
          padding: "0rem",
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          centered
          sx={{
            width: "100%",
            mb: "1rem",
            backgroundColor: "white !important",
            borderBottom: "1px solid #e0e0e0",
            borderTop: "1px solid #e0e0e0",
            borderRadius: "0",
            padding: "0",
            "& .MuiTab-root": {
              minHeight: "64px !important",
              color: "black",
              maxWidth: "220px !important",
              "&:hover": {
                color: "#6ac4bb !important",
              },
            },
            "& .Mui-selected": {
              color: "#6ac4bb !important",
              borderBottom: "3px solid #7eddd3",
              borderRadius: "0",
            },
            "& .MuiTabs-indicator": {
              display: "none",
              borderBottom: "1px solid black",
            },
          }}
        >
          <Tab label="O PROIZVODU" sx={{ fontSize: "14px" }} />
          <Tab label="SASTOJCI" sx={{ fontSize: "14px" }} />
          <Tab label="NAČIN UPOTREBE" sx={{ fontSize: "14px" }} />
        </Tabs>

        {tabIndex === 0 && (
          <ProductDescription
            sx={{
              px: "1rem",
              pt: "1rem",
              maxWidth: "900px",
              margin: "0 auto",
            }}
            titleSx={{ pb: "1rem" }}
            title="Daje rezultate već nakon 7 dana!"
            textVariant="body2"
            textArray={[
              "Uz redovnu upotrebu Mama za pet seruma, tvoja koža će biti dubinski hidrirana, tonirana i zaštićena, dok ćeš istovremeno uživati u luksuznom osjećaju prirodne njege.",
              "Prilagođen je svim tipovima kože, uključujući i osjetljivu kožu sklonu alergijama i ekcemima.",
              "Sadrži 100% biljna eterična i hladno cijeđena ulja (poput avokada, smilja, pšeničnih klica, kardamona i bosiljka)  koja su se tokom godina najbolje pokazala u smanjenju bora i sprječavanju nastanka novih.",
              "Hladno cijeđeno ulje pšeničnih klica, koji se nalazi u serumu, bogato je vitaminima E i A, što znači da pruža dodatnu zaštitu od štetnih sunčevih zraka i pomaže u smirivanju kože sklone ekcemima ili iritacijama.",
              "Nanosi se nekoliko kapi na očišćenu kožu lica i vrata, i zatim nježno umasira kružnim pokretima.",
              "Serum je idealan za večernju njegu zbog umirujućeg aromaterapijskog učinka.",
            ]}
          />
        )}
        {tabIndex === 1 && (
          <ProductDescription
            sx={{
              px: "1rem",
              pt: "1rem",
              maxWidth: "900px",
              margin: "0 auto",
            }}
            titleSx={{ pb: "1rem" }}
            title="Sastojci"
            textVariant="body2"
            textArray={[
              "100% biljna eterična i hladno cijeđena ulja među kojima su: argan, avokado, smilje, tamanu, pšenične klice, limun i bosiljak. Ovi sastojci, poznati su po svojim regenerativnim i umirujućim svojstvima.",
              "Savršen je za sve tipove kože, uključujući osjetljivu i sklonu alergijama. Zahvaljujući hladno cijeđenom ulju pšeničnih klica, bogatom vitaminima E i A, serum pruža dodatnu zaštitu od štetnih sunčevih zraka i pomaže u smirivanju kože sklone ekcemima ili iritacijama.",
              "Uz delikatne arome bosiljka, kardamoma i timijana, ovaj serum ne samo da njeguje tvoju kožu, već pruža trenutke opuštanja i harmonije prije spavanja.",
              "Arganovo ulje intezivno hidrira i poboljšava elasgtičnost kože, avokado obnavlja ošećene stanice, dok je ulje tamanu poznato po zacjeljivanju ožiljaka i smirivanju upala.",
              "Smilje smanje bore, regeneriše kožu, a bosiljak djeluje protiv akni i nečistoća.",
            ]}
          />
        )}
        {tabIndex === 2 && (
          <ProductDescription
            sx={{
              px: "1rem",
              pt: "1rem",
              maxWidth: "900px",
              margin: "0 auto",
            }}
            titleSx={{ pb: "1rem" }}
            title="Način upotrebe"
            textVariant="body2"
            textArray={[
              "Mama za pet serum možeš koristiti svakodnevno.",
              "1. Priprema kože: Prije nanošenja seruma, očisti lice blagim sredstvom za čišćenje kako bi uklonila nečistoće, šminku i višak masnoće.",
              "2. Nanošenje seruma: Dovoljno je 3–4 kapi seruma za cijelo lice i vrat. Zahvaljujući visokoj koncentraciji aktivnih sastojaka, mala količina pruža maksimalan učinak. Kapnite serum na dlanove ili direktno na čistu kožu lica. Nježno utapkajte serum vrhovima prstiju, počevši od središta lica prema van.",
              "3. Dodatna njega: Nakon što se serum u potpunosti upije (što traje svega nekoliko sekundi), možeš nanijeti kremu protv bora Mama za pet kako bi zadržala vlagu u koži i zaključala aktivne sastojke.",
              "Jutarnja rutina: Serum možeš koristiti kao osnovu ispod dnevne kreme i šminke.",
              "Večernja rutina: Za maksimalnu regeneraciju, nanesi serum na čistu kožu prije spavanja. Njegove aromaterapijske prednosti pomoći će ti da se opustiš.",
            ]}
          />
        )}
      </Grid>

      <Divider sx={{ mb: "0", mt: "3rem" }} />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        pt="1rem"
        px={{ xs: "1rem", md: "0" }}
      >
        <SoftTypography variant="h4" sx={{ color: "#000" }}>
          Pogledajte i ostale proizvode koji bi vam se mogli svidjeti
        </SoftTypography>
      </SoftBox>
      <Divider sx={{ mb: "0" }} />

      <ScrollableProductList
        products={products.filter((p) => p.name !== "Serum za lice")}
        selectedCurrency={selectedCurrency}
        handleAddToCart={(product) => handleAdd(product, "suggestions_add_to_cart_button")}
      />

      <SoftBox display="flex" justifyContent="center" mt="4rem" />

      <Reviews productIds={[3]} />
    </PageLayout>
  );
};

export default Serum;
