import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Swal from "sweetalert2";
import PropTypes from "prop-types";

import { useSoftUIController } from "context";

import { Stack } from "@mui/system";
import { Card, Grid, Icon, Menu, MenuItem } from "@mui/material";

import colors from "assets/theme/base/colors";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftSnackbar from "components/SoftSnackbar";
import SoftTypography from "components/SoftTypography";

import DataTable from "pages/admin/orders/components/orders-table";
import DashboardLayout from "pages/layout/dashboard-layout";
import ActionCell from "pages/admin/orders/components/action-cell";
import ImportOrdersModal from "pages/admin/orders/components/import-orders-modal";
import CreateNewOrderModal from "pages/admin/orders/components/create-new-order-modal";

import dataTableData from "pages/admin/orders/data/orders-table-data";
import dataCountries from "pages/common/data/countries";
import { get, patch, del, SERVER_URL_PREFIX, RESOURCES_SERVER_URL_PREFIX } from "api";
import { useDocumentTitle } from "hooks/use-document-title";

const Orders = (props) => {
  useDocumentTitle("Narudžbe | Mama za pet - Platforma");
  const [controller] = useSoftUIController();
  const { selectedOrders } = controller;
  const [data, setData] = useState(dataTableData.rows);
  const columns = useMemo(() => dataTableData.columns, [dataTableData]);
  const [forceUpdate, setForceUpdate] = useState(true);
  const [totalElements, setTotalElements] = useState(0);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isOrderedListChanged, setIsOrderedListChanged] = useState(false);

  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [openImportModal, setOpenImportModal] = useState(false);
  const handleOpenImportModal = () => setOpenImportModal(true);
  const handleCloseImportModal = () => {
    setOpenImportModal(false);
  }

  const [editOrderId, setEditOrderId] = useState(null);
  const [pageCount, setPageCount] = useState(10);
  const fetchIdRef = useRef(0)

  const openOrderCreateModal = () => { setEditMode(false); setOpen(true); setEditOrderId(null); }
  const openOrderEditModal = (orderId) => { setEditMode(true); setOpen(true); setEditOrderId(orderId) };

  // sve vezano za notifikaciju ce trebati biti izdvojeno i refaktorisano
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [successCreatingOrder, setSuccessCreatingOrder] = useState(false);
  const [errorCreatingOrder, setErrorCreatingOrder] = useState(false);
  const openSuccessCreatingOrder = () => setSuccessCreatingOrder(true);
  const closeSuccessCreatingOrder = () => setSuccessCreatingOrder(false);
  const openErrorCreatingOrder = () => setErrorCreatingOrder(true);
  const closeErrorCreatingOrder = () => setErrorCreatingOrder(false);

  const [successUpdatingOrder, setSuccessUpdatingOrder] = useState(false);
  const [errorUpdatingOrder, setErrorUpdatingOrder] = useState(false);
  const openSuccessUpdatingOrder = () => setSuccessUpdatingOrder(true);
  const closeSuccessUpdatingOrder = () => setSuccessUpdatingOrder(false);
  const openErrorUpdatingOrder = () => setErrorUpdatingOrder(true);
  const closeErrorUpdatingOrder = () => setErrorUpdatingOrder(false);

  const [filterState, setFilterState] = useState({
    country: dataCountries
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((c) => ({ key: c.value, label: c.name, checked: false })),
    shipmentStatus: [
      { key: "ORDERED", label: "Naručeno", checked: false },
      { key: "SHIPPED", label: "Isporučeno", checked: false },
      { key: "READY_TO_SHIP", label: "Spremno za isporuku", checked: false },
      { key: "RETURNED", label: "Vraćeno", checked: false },
      { key: "DELIVERED", label: "Dostavljeno", checked: false },
      { key: "CANCELED", label: "Otkazano", checked: false }
    ],
    paymentStatus: [
      { key: "PAID", label: "Plaćeno", checked: false },
      { key: "NOT_PAID", label: "Nije plaćeno", checked: false },
    ],
  });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchQuery, setSearchQuery] = useState();

  const handleOpen = () => setOpen(true);
  const handleClose = (e, reason, created, updated, error) => {
    if (reason && reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }
    if (created && !error) {
      setIsOrderedListChanged(prevState => !prevState);
      openSuccessCreatingOrder();
    } if (created && error) {
      openErrorCreatingOrder();
    }
    else if (updated && !error) {
      setIsOrderedListChanged(prevState => !prevState);
      openSuccessUpdatingOrder();
    } else if (updated && error) {
      openErrorUpdatingOrder();
    }
    setOpen(false);
    setForceUpdate(prev => !prev);
  }

  const renderSuccessSB = (
    <SoftSnackbar
      color="success"
      icon="check"
      title="Brisanje narudžbe"
      content="Uspješno ste obrisali narudžbu"
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <SoftSnackbar
      color="error"
      icon="warning"
      title="Greška"
      content="Došlo je do greške prilikom brisanja narudžbe"
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const renderSuccessCreatingOrder = (
    <SoftSnackbar
      color="success"
      icon="check"
      title="Kreiranje narudžbe"
      content="Uspješno ste kreirali narudžbu"
      dateTime=""
      open={successCreatingOrder}
      onClose={closeSuccessCreatingOrder}
      close={closeSuccessCreatingOrder}
      bgWhite
    />
  );

  const renderErrorCreatingOrder = (
    <SoftSnackbar
      color="error"
      icon="warning"
      title="Greška"
      content="Došlo je do greške prilikom kreiranja narudžbe"
      dateTime=""
      open={errorCreatingOrder}
      onClose={closeErrorCreatingOrder}
      close={closeErrorCreatingOrder}
      bgWhite
    />
  );

  const renderSuccesUpdatingOrder = (
    <SoftSnackbar
      color="success"
      icon="check"
      title="Narudžba ažurirana"
      content="Narudžba je uspješno ažurirana"
      dateTime=""
      open={successUpdatingOrder}
      onClose={closeSuccessUpdatingOrder}
      close={closeSuccessUpdatingOrder}
      bgWhite
    />
  );

  const renderErrorUpdatingOrder = (
    <SoftSnackbar
      color="error"
      icon="warning"
      title="Greška"
      content="Došlo je do greške prilikom ažuriranja narudžbe"
      dateTime=""
      open={errorUpdatingOrder}
      onClose={closeErrorUpdatingOrder}
      close={closeErrorUpdatingOrder}
      bgWhite
    />
  );

  const [readyChecked, setReadyChecked] = useState(false);

  const ActionButtons = ({ order }) => {
    return (
      <ActionCell
        orderId={order.id}
        onDelete={() => {
          deleteOrder(order.id);
        }}
        handleReadyCheck={() => {
          patch(`${SERVER_URL_PREFIX}/api/admin/order/${order.id}`, { shippingStatus: "READY_TO_SHIP" })
            .then(response => response.text())
            .then(data => data == "true" ? setReadyChecked(true) : openErrorSB())
        }}
        readyChecked={readyChecked}
        handleEdit={() => openOrderEditModal(order.id)}
      />
    )
  }

  const deleteOrder = (orderId) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-info",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Obrišite narudžbu?",
        text: "Narudžba će biti trajno obrisana.",
        showCancelButton: true,
        confirmButtonText: "Obriši",
        cancelButtonText: "Odustani",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          del(`${SERVER_URL_PREFIX}/api/admin/order/${orderId}`)
            .then(response => response.text())
            .then(data => {
              data == "true" ? openSuccessSB() : openErrorSB();
              setData(prevData => prevData.filter(p => p.id != `#${orderId}`));
            });
          // newSwal.fire("Deleted!", "Your file has been deleted.", "success");
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          // newSwal.fire("Cancelled", "Your imaginary file is safe :)", "error");
        }
      });
  }

  ActionButtons.propTypes = {
    order: PropTypes.object
  }

  const prepareFilterQueryString = (filterState) => {
    let queryString = '';
    Object.keys(filterState).forEach(k => {
      if (filterState[k].some(e => e.checked)) {
        queryString += `${k}=` + filterState[k].filter(c => c.checked ? c.key : '').map(e => e.key).join(',') + '&'
      }
    }
    );
    if (queryString.length > 0) {
      queryString = queryString.substring(0, queryString.length - 1);
    }
    return queryString;
  }

  const prepareIntervalQueryString = (startDate, endDate) => {
    const dates = [];
    if (startDate && startDate.length > 0) {
      dates.push(`dateTimeFrom=${startDate[0].toISOString()}`);
    }
    if (endDate && endDate.length > 0) {
      dates.push(`dateTimeTo=${endDate[0].toISOString()}`);
    }
    return dates.join("&");
  }

  const fetchTableData = useCallback((page, size, filterState, startDate, endDate, searchQuery, selectAllOrdersOptions) => {
    setLoading(true);
    const fetchId = ++fetchIdRef.current;
    const filterQueryString = prepareFilterQueryString(filterState);
    const intervalQueryString = prepareIntervalQueryString(startDate, endDate);
    const queryParams = [];
    if (`${page}`) {
      queryParams.push(`page=${page}`);
    }
    if (size) {
      queryParams.push(`size=${size}`);
    }
    queryParams.push(`sort=id,desc`);
    if (filterQueryString) {
      queryParams.push(`${filterQueryString}`);
    }
    if (intervalQueryString) {
      queryParams.push(`${intervalQueryString}`);
    }
    if (searchQuery) {
      queryParams.push(`query=${searchQuery}`);
    }
    const queryString = queryParams.join('&');
    get(`${SERVER_URL_PREFIX}/api/admin/orders2?${queryString}`)
      .then(response => response.json())
      .then(data => {
        if (fetchId === fetchIdRef.current) {
          const mappedProducts = data.content.map(o => ({
            id: `#${o.id}`,
            date: o.timestamp,
            country: { name: o.customer.country.name, image: `${RESOURCES_SERVER_URL_PREFIX}${o.customer.country.imageLocation}` },
            shipmentStatus: o.shippingStatus,
            paymentStatus: o.paymentStatus,
            customer: [o.customer.fullName, {}],
            notes: o.notes,
            products: o.products.map(p => ({ name: p.name, quantity: p.quantity })),
            price: { text: o.price, preffix: o.currency },
            actions: <ActionButtons order={o} />
          }))

          if (selectAllOrdersOptions) {
            const {
              handler,
              currentPage,
            } = selectAllOrdersOptions;
            setData(mappedProducts.slice(currentPage * 10, currentPage * 10 + 10));
            setPageCount(Math.ceil(data.totalElements / 10));
            handler(mappedProducts.map(p => p.id));
          } else {
            setData(mappedProducts);
            setPageCount(data.totalPages);
          }
          setTotalElements(data.totalElements);
          setError(null);
        }
      })
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, []);


  const [menu, setMenu] = useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const filterSelected = useMemo(() => {
    for (const key of [...Object.keys(filterState)]) {
      if (filterState[key].some(e => e.checked)) {
        return true;
      }
    }
    return false;
  }, [filterState]);

  const orderSelected = useMemo(() => {
    return selectedOrders && selectedOrders.length > 0;
  }, [selectedOrders]);

  const prepareExportQueryString = (exportFilterClicked) => {
    let queryString = "";
    if (!exportFilterClicked && orderSelected) {
      queryString = `selectedOrderIds=${selectedOrders.map(o => o.substring(1, o.length)).join(',')}`;
    }
    else if (exportFilterClicked && filterSelected) {
      queryString = prepareFilterQueryString(filterState);
    }
    return queryString;
  }

  const exportSelectedOrdersToFile = () => {
    const selectedOrderIdsString = selectedOrders.map(o => o.substring(1, o.length)).join(',');
    if (!selectedOrderIdsString) {
      return;
    }
    get(`${SERVER_URL_PREFIX}/api/admin/export?ids=${selectedOrderIdsString}`)
      .then(res => res.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `narudzbe-${new Date().toISOString()}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        closeMenu();
      })
      .catch((e) => console.log("error occured", e));
  }

  const exportSelectedOrdersToPostLabelFile = () => {
    const selectedOrderIdsString = selectedOrders.map(o => o.substring(1, o.length)).join(',');
    if (!selectedOrderIdsString) {
      return;
    }
    get(`${SERVER_URL_PREFIX}/api/admin/postexport?ids=${selectedOrderIdsString}`)
      .then(res => res.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `posta-narudzbe-${new Date().toISOString()}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        closeMenu();
      })
      .catch((e) => console.log("error occured", e));
  }

  const exportCSV = (exportFilterClicked) => () => {
    const queryString = prepareExportQueryString(exportFilterClicked);
    if (!queryString) {
      return;
    }

    get(`${SERVER_URL_PREFIX}/api/admin/bulk?${queryString}`)
      .then(res => res.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `posta-${new Date().toISOString()}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        closeMenu();
      })
      .catch((e) => console.log("error occured", e));
  }

  const exportPDF = (exportFilterClicked) => () => {
    const queryString = prepareExportQueryString(exportFilterClicked);
    if (!queryString) {
      return;
    }

    get(`${SERVER_URL_PREFIX}/api/admin/print?${queryString}`)
      .then(res => res.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `naljepnice-${new Date().toISOString()}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        closeMenu();
      })
      .catch((e) => console.log("error occured", e));
  }

  const { light } = colors;
  const menuItemStyle = {
    "&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
      borderBottom: `0`,
      backgroundColor: `${light.main} !important`,
      borderRadius: '5px !important'
    }
  };
  const renderExportMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
      sx={{
        ".MuiMenu-paper": {
          backgroundColor: "white !important"
        }
      }}
    >
      <MenuItem
        onClick={exportSelectedOrdersToFile}
        disabled={!orderSelected}
        sx={menuItemStyle}
      >
        Fajl za narudžbe
      </MenuItem>
      <MenuItem
        onClick={exportSelectedOrdersToPostLabelFile}
        disabled={!orderSelected}
        sx={menuItemStyle}
      >
        Fajl za poštu
      </MenuItem>
      {/* <MenuItem
        onClick={exportCSV(true)}
        disabled={!filterSelected}
        sx={menuItemStyle}
      >
        Pošta - filtrirani
      </MenuItem>
      <MenuItem
        onClick={exportCSV(false)}
        disabled={!orderSelected}
        sx={menuItemStyle}>
        Pošta - selektovani
      </MenuItem>
      <MenuItem
        onClick={exportPDF(true)}
        disabled={!filterSelected}
        sx={menuItemStyle}>
        Naljepnice BIH - filtrirani
      </MenuItem>
      <MenuItem
        onClick={exportPDF(false)}
        disabled={!orderSelected}
        sx={menuItemStyle} >
        Naljepnice BIH - selektovani
      </MenuItem>
      <MenuItem
        onClick={exportPDF(true)}
        disabled={!filterSelected}
        sx={menuItemStyle}>
        Naljepnice - filtrirani
      </MenuItem>
      <MenuItem
        onClick={exportPDF(false)}
        disabled={!orderSelected}
        sx={menuItemStyle} >
        Naljepnice - selektovani
      </MenuItem> */}
    </Menu>
  );

  return (
    <DashboardLayout>
      <CreateNewOrderModal
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        editMode={editMode}
        orderId={editOrderId}
      />
      <ImportOrdersModal
        open={openImportModal}
        handleClose={handleCloseImportModal}
        handleOpen={handleOpenImportModal}
        setForceUpdate={setForceUpdate}
      />
      <Grid container mt={{ xs: "0", md: "1rem" }}>
        <Grid item xs={12}>
          <SoftBox>
            <Card>
              <SoftBox display={{ xs: "none", md: "flex" }} justifyContent="space-between" alignItems="flex-start" p={3}>
                <SoftBox lineHeight={1}>
                  <SoftTypography variant="h4" fontWeight="medium">
                    Narudžbe
                  </SoftTypography>
                </SoftBox>
                <Stack spacing={1} direction="row">
                  <SoftButton variant={"outlined"} color="dark" onClick={() => setForceUpdate(prev => !prev)}>
                    <Icon>refresh</Icon>&nbsp;
                  </SoftButton>
                  <SoftButton variant={"outlined"} color="dark" onClick={handleOpenImportModal}>
                    <Icon>download</Icon>&nbsp;
                    Import
                  </SoftButton>
                  <SoftButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
                    Export&nbsp;
                    <Icon>keyboard_arrow_down</Icon>
                  </SoftButton>
                  {renderExportMenu}
                  <SoftButton variant="gradient" color="success" size="small" onClick={openOrderCreateModal}>
                    {/* <Icon sx={{ fontSize: "1.25rem !important" }}>add</Icon>&nbsp; */}
                    nova narudžba
                  </SoftButton>
                </Stack>
              </SoftBox>
              <SoftBox display={{ xs: "flex", md: "none" }} flexDirection="column" justifyContent="space-between" alignItems="flex-start" p="1rem" pb={0} gap="1rem">
                <SoftBox lineHeight={1} display="flex" justifyContent="space-between" width="100%">
                  <SoftTypography variant="h4" fontWeight="medium">
                    Narudžbe
                  </SoftTypography>
                  <SoftButton variant={"outlined"} color="dark" onClick={() => setForceUpdate(prev => !prev)}>
                    <Icon>refresh</Icon>&nbsp;
                  </SoftButton>
                </SoftBox>
                <Stack spacing={1} direction="row" justifyContent="flex-end" width="100%">
                  <SoftButton variant={"outlined"} color="dark" onClick={handleOpenImportModal}>
                    Import
                  </SoftButton>
                  <SoftButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
                    Export&nbsp;
                    <Icon>keyboard_arrow_down</Icon>
                  </SoftButton>
                  {renderExportMenu}
                  <SoftButton variant="gradient" color="success" size="medium" onClick={openOrderCreateModal}>
                    <Icon sx={{ fontSize: "2rem" }}>add</Icon>&nbsp;
                  </SoftButton>
                </Stack>
              </SoftBox>
              <DataTable
                table={data}
                entriesPerPage={false}
                canSearch
                controlledPageCount={pageCount}
                fetchData={fetchTableData}
                loading={loading}
                error={error}
                columns={columns}
                data={data}
                filterState={filterState}
                setFilterState={setFilterState}
                startDate={startDate}
                setStartDate={setStartDate}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                endDate={endDate}
                setEndDate={setEndDate}
                forceUpdate={forceUpdate}
                setForceUpdate={setForceUpdate}
                totalElements={totalElements}
              />
              {renderSuccessSB}
              {renderErrorSB}
              {renderSuccessCreatingOrder}
              {renderErrorCreatingOrder}
              {renderSuccesUpdatingOrder}
              {renderErrorUpdatingOrder}
            </Card>
          </SoftBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  )
}

export default Orders;